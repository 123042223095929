/**
 * 分组实体
 */
export interface GroupModel {
  id: string;
  mouldGroupName: string;
  mouldGroupType: string; // "图片" | "话术" | "图文";
  num: number;
}

/**
 * 素材（模板）实体
 */
export interface MaterialModel {
  id: string;
  mouldTitle: string;
  mould: string;
  mouldImgName: Array<string>;
  mouldType: string; // "图片" | "话术" | "图文";
  mouldGroupId: string;
  mouldGroupName: string;
}

// 订单相关
export interface OrderStatus {
  status: "待看" | "正在看" | "已看" | "";
}

/**
 * 订单实体
 */
export interface Order {
  id: string;
  jsonstring: string;
  name: string;
  phone: string;
  age: number | string;
  height: number | string;
  weight: number | string;
  faceImgName: Array<string>;
  faceImgType: string;
  contentId: string;
  remark: string;
  orderType: string;
  orderStatus: string;
  createUserId: string;
  createtime: string;
  updateUserId: string;
  fileValid?: string;
  groupId?: number | string; // 分组
  groupName?: string;
  sex?: string;
  appeal?: string; // 改善诉求
  relatedMsg?: string;
}
/**
 * 保存订单内容
 */
export interface OrderContent {
  id: string;
  content: string;
  bgColor?: string;
}

/**
 * 编辑新增订单图片组件上传类型
 */
export interface FileList {
  name: string;
  url: string;
}

/**
 * 富文本保存订单内容emit实体
 */
export interface saveOrderEmit {
  content: string;
  isNext: boolean;
  isAutoSave: boolean;
  isSync: boolean;
}

/**
 * 跳转到编辑工作台的实体
 */
export interface editContentRouter {
  gid: string; // 模板分组ID
  gname: string; // 模板分组组名
  id: string; // 订单||模板ID
  type: string; // mix||workbench
}

/**
 * 导出、预览实体
 */
export interface ExportRouter {
  id: string; // 模板ID或订单ID
  type: string; // workbench:订单 mix:图文
  isExport: string;
}

export enum OrderStatusEnum {
  wating = "待看",
  operating = "正在看",
  finish = "已看",
}

/**
 * 统计数据实体
 */
export interface HomeTotal {
  orderFinishnetWaitTotal: number;
  orderFinishedTotal: number;
  orderTotal: number;
}

/**
 * 图片地址
 */
const PicUrl = "https://facemaster.meb.fit/api/face/getOssImage?fileName=";
export { PicUrl };

/**
 * VUEX相关
 */
export interface userIdPayload {
  userId: string;
}

export interface userNamePayload {
  name: string;
}

/**
 * 分组
 */
export interface groupObj {
  userId: string;
  userName: string;
  realName: string;
  proGroupId: number;
  proGroupName: string;
}
